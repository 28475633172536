export const integrateAnalyticsPlatforms = () => {
	try {
		integrateGA()
		integrateAmplitude()
	} catch (e) {
		console.error("Error integratig analytics platforms: ", e)
	}
}

export const integrateGA = () => {
	const context = getContext()
	context.dataLayer = context.dataLayer || []
	context.gtag = function () {
		context.dataLayer.push(arguments)
	}

	const GAKey = import.meta.env.VITE_GA_KEY
	if (!GAKey) {
		console.error(
			"Error: VITE_GA_KEY is not defined. Please check your environment variables."
		)
		return
	}

	addAnalyticsScript(`https://www.googletagmanager.com/gtag/js?id=${GAKey}`, () => {
		context.dataLayer = context.dataLayer || []
		context.gtag("js", new Date())
		context.gtag("config", GAKey)
	})
}

export const integrateAmplitude = () => {
	const context = getContext()
	const AmplitudeKey = import.meta.env.VITE_AMPLITUDE_KEY
	if (!AmplitudeKey) {
		console.error(
			"Error: VITE_AMPLITUDE_KEY is not defined. Please check your environment variables."
		)
		return
	}
	addAnalyticsScript("https://cdn.amplitude.com/libs/analytics-browser-2.10.0-min.js.gz", () => {
		addAnalyticsScript(
			"https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.6.17-min.js.gz",
			() => {
				context.amplitude
					.add(context.sessionReplay.plugin({ sampleRate: 1 }))
					.promise.then(function () {
						context.amplitude.init(AmplitudeKey, {
							autocapture: { elementInteractions: true },
						})
					})
					.catch((e: any) => {
						console.error("Error initializing Amplitude: ", e)
					})
			}
		)
	})
}

const addAnalyticsScript = (src: string, callback: any) => {
	const script = document.createElement("script")
	script.src = src
	script.async = true
	if (callback) script.onload = callback
	script.onerror = (e) => {
		console.error(`Error loading script: ${src}`, e)
	}
	document.head.appendChild(script)
}

const getContext: any = () => window
